import React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import MainTitle from "../components/Common/MainTitle";
import TutorialCard from "../components/Common/TutorialCard";
import PostCard from "../components/Common/PostCard";
import TagList from "../components/TemplateTag/TagList";
// import ADBox from "../components/TemplateTag/ADBox";

export const pageQuery = graphql`
  query($tag: String) {
    allTags: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//content/data/([a-z])+//" } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
        edges {
          node {
            fields {
              slug
            }
          }
        }
      }
    }

    filteredTut: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//content/data/([a-z])+/tutorials//" }
        frontmatter: { tags: { in: [$tag] } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt(truncate: true)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY년 MM월 DD일")
            title
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  src
                }
              }
            }
          }
        }
      }
    }

    filteredPosts: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//content/data/([a-z])+/posts//" }
        frontmatter: { tags: { in: [$tag] } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt(truncate: true)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY년 MM월 DD일")
            title
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

const TagTemplate = ({ location, data, pageContext }) => {
  const { tag, menu } = pageContext;
  return (
    <Layout location={location}>
      <SEO title={tag} />

      <main role="main" style={{ marginTop: "56px" }}>
        <Container>
          <Row>
            <Col lg={2} className="d-none d-lg-block">
              <aside className="sticky-item">
                <TagList tags={data.allTags.group} menu={menu} section="tags" />
                {/* <ADBox /> */}
              </aside>
            </Col>

            <Col lg={10}>
              {data.filteredTut.edges.length > 0 && (
                <div className="my-5">
                  <MainTitle title="Tutorials" linkTo={`/${menu}/tutorials`} />
                  <Row>
                    {data.filteredTut.edges.map(({ node }) => {
                      return (
                        <Col
                          key={node.fields.slug}
                          xl={4}
                          md={6}
                          style={{ marginBottom: "20px" }}
                        >
                          <TutorialCard node={node} />
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              )}
              <div className="my-5">
                <MainTitle title="Posts" linkTo={`/${menu}/posts`} />
                <Row>
                  {data.filteredPosts.edges.map(({ node }) => {
                    return (
                      <Col
                        key={node.fields.slug}
                        xl={6}
                        style={{ marginBottom: "20px" }}
                      >
                        <PostCard node={node} />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  );
};

export default TagTemplate;
